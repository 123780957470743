// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-index-js": () => import("../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-2012-medical-mission-js": () => import("../src/pages/projects/2012-medical-mission.js" /* webpackChunkName: "component---src-pages-projects-2012-medical-mission-js" */),
  "component---src-pages-projects-2013-aeta-outreach-js": () => import("../src/pages/projects/2013-aeta-outreach.js" /* webpackChunkName: "component---src-pages-projects-2013-aeta-outreach-js" */),
  "component---src-pages-projects-2015-school-build-out-js": () => import("../src/pages/projects/2015-school-build-out.js" /* webpackChunkName: "component---src-pages-projects-2015-school-build-out-js" */),
  "component---src-pages-projects-2017-aeta-outreach-js": () => import("../src/pages/projects/2017-aeta-outreach.js" /* webpackChunkName: "component---src-pages-projects-2017-aeta-outreach-js" */),
  "component---src-pages-projects-2018-19-feeding-program-js": () => import("../src/pages/projects/2018-19-feeding-program.js" /* webpackChunkName: "component---src-pages-projects-2018-19-feeding-program-js" */),
  "component---src-pages-projects-2019-20-feeding-program-js": () => import("../src/pages/projects/2019-20-feeding-program.js" /* webpackChunkName: "component---src-pages-projects-2019-20-feeding-program-js" */),
  "component---src-pages-projects-index-js": () => import("../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

